.spot-title {
  font-weight: bold;
  line-height: 2;
}

.spot-frequency {
  color: #464646;
  line-height: 1.5;
}

.spot-features {
  line-height: 3;
}

.spot-features span {
  background-color: #3f6fbb; /* rgba(62, 151, 69, 0.808); */
  color: white;
  border-radius: 0.3rem;
  padding: 0.5rem;
  margin-right: 0.3rem;
}

.spot-description {
  line-height: 1.3;
}

.spot-footer {
  padding: 0.3rem;
}
.spot-footer a {
  background-color: #6c3986;
  color: white;
  padding: 0.5rem;
  border-radius: 0.3rem;
  text-decoration: none;
}
