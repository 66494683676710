.about-container {
  display: flex;
  flex-direction: column;
}

.about-header {
  padding: 0 1rem;
}

.about-cta-container {
  padding: 0 1rem;
}

.about-why {
  background-color: #464646;
  color: #ffffffea;
  padding: 1rem;
  line-height: 1.2;
}

.about-why a {
  color: white;
}

.about-why svg {
  width: 1rem;
  height: 1rem;
}
