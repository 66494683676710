$black-9: #eeeeee;
$black-8: #e2e2e2;
$black-7: #cecece;
$black-6: #afafaf;
$black-5: #8f8f8f;
$black-4: #6d6d6d;
$black-3: #464646;
$black-2: #343434;
$black-1: #131313;

$springboard-blue-2: #769eda;
$springboard-blue-1: #6189c7;
$springboard-blue: #3f6fbb;

$springboard-purple: #6c3986;

$springboard-red: #af363b;
